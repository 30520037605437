.main {
  text-align: center;
}
p {
  font-size: 1rem;
}

.active {
  font-weight: 600;
}

.my-logo {
  padding: 0.5rem;
}

.top-title {
  font-size: 3rem;
  font-weight: 200;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

.description{
  background-color: #15161a;
  text-align: left;
  color: white;
  line-height: 1.5em;
  padding: 0.5rem;
}

.nav-link {
  color: black;
}

.top-wrapper {
  position: absolute;
  left: 0vw;
  right: 0vw;
  top: 40vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
}

.wobble-chair, .carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: invert(80%);
}

.carousel-control-prev-icon:hover,
.carousel-control-next-icon:hover {
  filter: invert(100%);
}

.arrow-up {
  position: relative;
  top: 10px;
}

.arrow-down {
  position: relative;
  bottom: 10px;
}
.ecommerce-bg {
  background: url("https://images.unsplash.com/photo-1603142791402-6982e699205f?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80") no-repeat fixed center;
  background-size: cover;
}
.webdev-bg {
  background: url("https://images.unsplash.com/photo-1502951682449-e5b93545d46e?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1266&q=80") no-repeat fixed center;
  background-size: cover;
}
.photo-bg {
  background: url("images/unicorn.jpeg") no-repeat fixed center;
  background-size: cover;
}

@media only screen and (max-width: 991px) {
  .full-container {
    min-height: calc(100vh - 75px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (min-width: 992px) {
  .full-container {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}